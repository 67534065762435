
export const apiUrl = process.env.VUE_APP_API_URL
export const isAuthGuardActive = true
export const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MSGSENDERID,
    appId: process.env.VUE_APP_FIREBASE_APPID,
    // measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENTID
}

export const socketUrl = process.env.VUE_APP_SOCKET_URL