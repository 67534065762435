import io from 'socket.io-client';
import {socketUrl} from "../constants/config";

let socketApp = null;
let socketIsConnected = false;
const initSocket = (_token) => {

    if (!socketUrl)
        return;

    if (socketIsConnected)
        return;

     socketApp = io(socketUrl, {
        query: {
            token: _token,
        },
         transports : ['websocket'],

    });

    socketApp.on('connect', () => {
        console.log('connect au serveur Socket.IO');
        socketIsConnected = true;
    });
    socketApp.on('disconnect', () => {
        console.log('disconnect au serveur Socket.IO');
        socketIsConnected = false
    });
}
const disconnectSOcket = () =>{
    if (socketApp) {
        socketApp.disconnect()
    }
}
const socketSubscribe = (eventName, fcn) => {
    if (!socketUrl)
        return;
    socketApp.on(eventName, fcn);
}
const socketUnSubscribe = (eventName, fcn) => {
    if (!socketUrl)
        return;
    socketApp.removeAllListeners(eventName);
}
export  {
    initSocket,
    socketSubscribe,
    socketUnSubscribe,
    disconnectSOcket,
}