import Vue from 'vue'
import App from './App.vue'
import moment from 'moment/src/moment';
import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);
import 'remixicon/fonts/remixicon.css'
import router from './router'
import store from './store'
import axios from "axios";

import { getCurrentUser, setCurrentUser } from '../src/utils';
import { apiUrl, firebaseConfig } from './constants/config';
import firebase from 'firebase/compat/app'
import "firebase/compat/messaging";
let myMediaQuery = window.matchMedia('(min-width: 600px)');
// initialiser firebase
let messaging =null
if(myMediaQuery.matches){
try{
  
    firebase.initializeApp(firebaseConfig);
    messaging = firebase.messaging();   
}
catch(err){
    console.log("Ce navigateur ne prend pas en charge les API requises pour utiliser le SDK Firebase")
}
}
export default messaging ;

import { BootstrapVue} from 'bootstrap-vue';
Vue.use(BootstrapVue);

// Colxx Component Add
import Colxx from './components/common/Colxx'
Vue.component('b-colxx', Colxx);
// Notification Component
import Notifications from './components/common/Notification'
Vue.use(Notifications);

import VueBreadcrumbs from 'vue-breadcrumbs'
Vue.use(VueBreadcrumbs);

import Acl from './acl/Acl';
Vue.use(Acl);

import fr from './locales/fr.json'
import VueI18n from 'vue-i18n'
import {disconnectSOcket} from "@/store/socket";
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: 'fr',
    messages: {
        fr
    },
})

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});
Vue.filter('formatHeure', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm')
    }
});

Vue.filter('formatDateTime', function(value) {
    let format = 'DD/MM/YYYY HH:mm';
    if (value) {
        return moment(String(value)).format(format)
    }
});
Vue.filter('upperCased', function(data) {
    let upper = [];
    data.split(" ").forEach((word) => {
        upper.push(word.toUpperCase());
    });
    return upper.join(" ");
});

Vue.filter('truncate', function(data, n) {
    if (data) {
        return data.length > n ? data.substr(0, n - 1) + '...' : data
    }
});
Vue.filter('firstLetter', function(data) {
    if (data) {
        return data.length > 0 ? data.substr(0, 1) : data
    }
});

axios.defaults.baseURL = `${apiUrl}`;
axios.interceptors.response.use(function(response) {
        return response
    }, 
    function(error) {
        let data = JSON.parse(localStorage.getItem('user'));
        if (data) {
            if (error.response.status === 401) {
                disconnectSOcket();
                setCurrentUser(null);  
                router.push({ name: 'LoginClient' });
            }
        }
        return Promise.reject(error)
    })
Vue.config.productionTip = false
new Vue({
    i18n,
    router,
    store,
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                if (to.name) {
                    document.title = 'EASYMAT | ' + this.$t('title.' + to.name);
                }
                if (getCurrentUser() || JSON.parse(window.localStorage.getItem("user"))) {
                    axios.defaults.headers.common['Authorization'] = "Bearer " + JSON.parse(window.localStorage.getItem("user")).token;
                }
            }
        },
    },
    render: h => h(App),

}).$mount('#app')